import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import Gallery from '../components/Gallery/Gallery';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';

const translations = [
  {
    locale: 'fr',
    url: '/realisations/',
  },
];

export default function CreationsPage({ data }) {
  const images = data.allImage.nodes.map(({ childImageSharp }) => {
    const gatsbyImageData = getImage(childImageSharp);

    return {
      gatsbyImageData,
    };
  });
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>Creations</PageHeader>

        <div className="container text-xl text-center">
          <Heading level={2} className="text-5xl">
            It’s more than a guitar. <br />
            It’s a Michel Pellerin work of art.
          </Heading>

          <p className="max-w-prose mx-auto">
            Michel Pellerin’s creativity and talent as a guitar maker know no
            bounds. No matter how challenging and complex the task, Pellerin
            never fails to impress customers with instruments made with
            meticulous care, skill and precision. What’s more, he knows how to
            match the various species of wood to produce a rich and unequalled
            sound.
          </p>
        </div>

        <section className="container">
          <div className=" wide-content-contained">
            <Gallery pictures={images} />
          </div>
        </section>

        {/* TODO: add video */}
        {/* <section className="container">
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                See videos of Pellerin guitars in action
              </Heading>
            </div>
          </div>

        </section> */}

        <section>
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                Rave reviews of Pellerin guitars
              </Heading>
            </div>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/en/testimonials" className="text-xl my-5">
              More testimonials
            </UnderlinedButton>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    allImage: allFile(
      filter: { relativeDirectory: { eq: "realisationsPictures" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 760)
        }
      }
    }
  }
`;
